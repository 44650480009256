import { FunctionComponent, useLayoutEffect, useState } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import BusinessCard from "../ digital-business-card";
import useLocalStorage from "../../hooks/useLocalStorage";
import { withHome } from "../../service/home";
import CheckoutPage from "../checkout/CheckoutPage";
import Page404 from "../Page404/Page404";
import ChangePassword from "../pages/ChangePassword";
import CreatePassword from "../pages/CreatePassword";
import BasicDashboard from "../pages/dashboards/BasicDashboard";
import MarketSharePerSegmentDashboard from "../pages/dashboards/marketSharePerSegment/MarketSharePerSegmentDashboard";
import PriceDashboard from "../pages/dashboards/price/PriceDashboard";
import ProfessionalDashboard from "../pages/dashboards/professional/ProfessionalDashboard";
import { FgvUser } from "../pages/fgv/definitions";
import { FgvEmbedPage } from "../pages/fgv/Embed";
import FgvContext, { DashboardType } from "../pages/fgv/FgvContext";
import FgvHome from "../pages/fgv/FgvHome";
import FgvMetodology from "../pages/fgv/FgvMetodology";
import { FgvPlus } from "../pages/fgv/FgvPlus";
import FgvPrivacity from "../pages/fgv/FgvPrivacity";
import FgvRegisterPage from "../pages/fgv/FgvRegisterPage";
import FgvTermsOfUse from "../pages/fgv/FgvTermsOfUse";
import ForgotPassword from "../pages/ForgotPassword";
import LoginPage from "../pages/LoginPage";
import MarketPerformanceDashboard from "../pages/MarketPerformanceDashboard";
import HomePlataform from "../pages/platform/home/Home";
import SingUpCompletePage from "../pages/SingUpCompletePage";
import SingUpPage from "../pages/SingUpPage";
import DashBoardPowerBI from "../power-bi/DashBoardPowerBI";
import TermsOfUsePage from "../termsOfUse/TermsOfUsePage";
import Modal from "../utils/Modal";
import { parseQuery } from "../utils/Util";
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";
import UsesAuthRoute from "./UsesAuthRoute";

const FgvRoutes = function () {
  const [fgvUser, setFgvUser] = useLocalStorage<FgvUser | null>(
    null,
    "FGV_USER"
  );
  const [visits, setVisits] = useLocalStorage<number>(0, "FGV_DASH_VISITS");
  const [dashboardLoaded, setDashboardLoaded] = useState<boolean>(false);
  const [currentDashboard, setCurrentDashboard] =
    useState<DashboardType>("BASIC");
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const value = {
    fgvUser,
    setFgvUser,
    visits,
    setVisits,
    dashboardLoaded,
    setDashboardLoaded,
    currentDashboard,
    setCurrentDashboard,
  };

  return (
    <FgvContext.Provider value={value}>
      <Switch>
        <Route component={FgvHome} path="/" exact />
        <Route component={FgvEmbedPage} path="/embed" exact />
        <Route component={FgvPlus} path="/plus" exact />
        <Route component={FgvRegisterPage} path="/register" exact />
        <Route component={FgvTermsOfUse} path="/termos-de-uso" exact />
        <Route component={FgvMetodology} path="/metodologia" exact />
        <Route component={FgvPrivacity} path="/politica-de-privacidade" exact />
        <Route path="/*">
          <Page404 />
        </Route>
      </Switch>
    </FgvContext.Provider>
  );
};

const DashboardsRoutes = withHome(() => {
  // These routes are separed because the withHome HOC is only needed
  // for then, loading the home unintentionally can generate unwanted
  // behaviour, por example, tring to load the home of an user
  // being register at the checkout page
  ReactGA.initialize("G-N188M8CBL6", { debug: false });
  return (
    <Switch>
      <PrivateRoute path={["/", "/app"]} exact>
        <HomePlataform />
      </PrivateRoute>
      <PrivateRoute path="/app/dashboards/market-performance" exact>
        <MarketPerformanceDashboard />
      </PrivateRoute>
      <PrivateRoute path="/app/dashboards/basic" exact>
        <BasicDashboard />
      </PrivateRoute>
      <PrivateRoute path="/app/dashboards/embed/:link" exact>
        <DashBoardPowerBI />
      </PrivateRoute>
      <PrivateRoute path="/app/dashboards/professional">
        <ProfessionalDashboard />
      </PrivateRoute>
      <PrivateRoute path="/app/dashboards/market-share-per-segment">
        <MarketSharePerSegmentDashboard />
      </PrivateRoute>
      <PrivateRoute path="/app/dashboards/price">
        <PriceDashboard />
      </PrivateRoute>
      <Route path="/politicas/termos-de-uso">
        <TermsOfUsePage type="termsOfUse" />
      </Route>
      <Route path="/politicas/cookies">
        <TermsOfUsePage type="cookies" />
      </Route>
      <Route path="*" exact>
        <Page404 />
      </Route>
    </Switch>
  );
});

const Router: FunctionComponent = function () {
  const location = window.location;
  const app = localStorage.getItem("app");
  // const locationHook = useLocation();

  const params = parseQuery(location.search);
  const hasEmbed = params["embed"];
  return (
    <BrowserRouter>
      <Modal>
        {(() => {
          if (location.host.match(/team\.ehorus\.com\.br/) || app === "team") {
            return (
              <switch>
                <Route>
                  <UsesAuthRoute path="/:user">
                    <BusinessCard />
                  </UsesAuthRoute>
                </Route>
              </switch>
            );
          } else if (
            location.host.match(/cestaconsumo\.ehorus\.com\.br/) ||
            app === "fgv" ||
            hasEmbed
          ) {
            return <FgvRoutes />;
          } else {
            return (
              <Switch>
                <GuestRoute path="/signup/complete" exact>
                  <SingUpCompletePage />
                </GuestRoute>
                <UsesAuthRoute path="/signup/:productType">
                  <CheckoutPage />
                </UsesAuthRoute>
                <Route path="/politica-de-privacidade">
                  <TermsOfUsePage type="all" />
                </Route>
                <Route path="/politicas/privacidade">
                  <TermsOfUsePage type="privacy" />
                </Route>
                <Route path="/politicas/termos-de-uso">
                  <TermsOfUsePage type="termsOfUse" />
                </Route>
                <Route path="/politicas/cookies">
                  <TermsOfUsePage type="cookies" />
                </Route>
                <GuestRoute path="/login" exact>
                  <LoginPage />
                </GuestRoute>
                <GuestRoute path="/signup" exact>
                  <SingUpPage />
                </GuestRoute>
                <GuestRoute path="/forgot/password" exact>
                  <ForgotPassword />
                </GuestRoute>
                <GuestRoute path="/change-password/:token" exact>
                  <ChangePassword />
                </GuestRoute>
                <GuestRoute path="/create-password/:token" exact>
                  <CreatePassword />
                </GuestRoute>
                <DashboardsRoutes />
                <Route path="*">
                  <Page404 />
                </Route>
              </Switch>
            );
          }
        })()}
      </Modal>
    </BrowserRouter>
  );
};

export default Router;
