import { useContext, useEffect, useState } from "react";
import EmbeddedDashboard from "./EmbeddedDashboard";
import FgvContext from "./FgvContext";

export const FgvEmbedPage = () => {
  const { setDashboardLoaded } = useContext(FgvContext);
  const [dashboardId, setDashboardId] = useState(
    "111E140_crs_brands_retail_metrics_professional"
  );

  function onDashboardLoaded(e: { link: string }) {
    setDashboardLoaded(true);
  }
  useEffect(() => () => setDashboardLoaded(false), [setDashboardLoaded]);
  useEffect(() => {
    if (window.location.hash === "#plus") {
      setDashboardId("70E104_horus_radar");
    }
    if (window.location.hash === "#ferramenta") {
      setDashboardId("111E140_crs_brands_retail_metrics_professional");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);
  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.setAttribute("http-equiv", "Content-Security-Policy");
    metaTag.setAttribute("content", "frame-ancestors 'self';");
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);
  return (
    <EmbeddedDashboard
      className="hd-fgb__body"
      link={dashboardId}
      onLoadDashboard={onDashboardLoaded}
    />
  );
};
